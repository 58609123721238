import { useTheme } from "@mui/material";
import React, { createContext, useState, useContext } from "react";
import { rgba } from "polished";
import { generatePopupContent } from "./utilities/generatePopupContent";

interface PopupProperties {
  [key: string]: any;
}

interface CurrentPopupContextProps {
  popupContent: string | null;
  setPopupContent: (properties: PopupProperties) => string;
  clearPopup: () => void;
}

const CurrentPopupContext = createContext<CurrentPopupContextProps | undefined>(
  undefined
);

export const CurrentPopupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [popupContent, setPopupContentState] = useState<string | null>(null);
  const theme = useTheme();

  const clearPopup = () => {
    setPopupContentState(null);
  };

  const setPopupContent = (properties: PopupProperties) => {
    const generatedHtml = generatePopupContent(properties, theme);

    setPopupContentState(generatedHtml);

    return generatedHtml;
  };

  return (
    <CurrentPopupContext.Provider
      value={{ popupContent, setPopupContent, clearPopup }}
    >
      {children}
    </CurrentPopupContext.Provider>
  );
};

export const useCurrentPopup = () => {
  const context = useContext(CurrentPopupContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentPopup must be used within a CurrentPopupProvider"
    );
  }
  return context;
};
