import React from "react";
import { Box } from "@mui/material";

const Editor: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 66px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="images/Editing Mockup.png"
        alt="Editor Mockup"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    </Box>
  );
};

export default Editor;
