import React, { createContext, useContext, useState, ReactNode } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

/**
 * Defines the structure of the user profile information.
 */
interface Profile {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
  hd?: string;
}

/**
 * Props for the UserProvider component.
 */
interface UserProviderProps {
  children: ReactNode;
}

/**
 * Defines the context type for user-related operations.
 */
interface UserContextType {
  profile: Profile | null;
  login: (profile: Profile, accessToken: string) => void;
  googleLogin: () => void;
  logout: () => void;
  basicAuthHeader?: { Authorization: string };
  oAuthHeader?: { Authorization: string };
}

const UserContext = createContext<UserContextType | undefined>(undefined);

/**
 * Custom hook to use the user context.
 * @returns The user context with profile and auth functions.
 */
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

/**
 * Builds the basic auth header using environment variables.
 * @returns An object containing the Authorization header or undefined if credentials are missing.
 */
const buildBasicAuthHeader = () => {
  const username = process.env.REACT_APP_BASIC_AUTH_USER;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  return username && password ? { Authorization: `Basic ${btoa(`${username}:${password}`)}` } : undefined;
};

/**
 * Fetches the user profile from Google's userinfo endpoint.
 * @param accessToken The access token obtained from Google OAuth.
 * @returns The user profile.
 */
const fetchProfile = async (accessToken: string) => {
  const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch user profile");
  }
  return response.json();
};

/**
 * Provides user authentication context.
 */
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const basicAuthHeader = buildBasicAuthHeader();

  /**
   * Updates the user context with profile data and access token.
   * @param profile The user profile data.
   * @param accessToken The access token for OAuth.
   */
  const login = (profile: Profile, accessToken: string) => {
    setProfile(profile);
    setAccessToken(accessToken);
  };

  /**
   * Initiates the Google login process.
   */
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const profile = await fetchProfile(tokenResponse.access_token);
        login(profile, tokenResponse.access_token);
      } catch (error) {
        console.error("Login Failed:", error);
      }
    },
    onError: (error) => console.error("Login Failed:", error),
  });

  /**
   * Logs out the current user.
   */
  const logout = () => {
    googleLogout();
    setProfile(null);
    setAccessToken(null);
  };

  const oAuthHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

  return (
    <UserContext.Provider value={{ profile, login, googleLogin, logout, basicAuthHeader, oAuthHeader }}>
      {children}
    </UserContext.Provider>
  );
};
