// @ts-nocheck

import React from "react";

interface LineStyle {
  "line-color": string;
  "line-width": number;
}

interface CircleStyle {
  "circle-radius": number;
  "circle-color": string;
  "circle-stroke-width": number;
  "circle-stroke-color": string;
}

interface PolygonStyle {
  "fill-color": string;
}

// Render SVG for Line
export const renderLineSVG = (style: LineStyle) => {
  const { "line-color": color, "line-width": width } = style;
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <line
        x1="2"
        y1="12"
        x2="22"
        y2="12"
        stroke={color}
        strokeWidth={width}
        strokeLinecap="round"
      />
    </svg>
  );
};

// Render SVG for Circle (Point)
export const renderCircleSVG = (style: CircleStyle) => {
  const {
    "circle-radius": radius,
    "circle-color": fillColor,
    "circle-stroke-width": strokeWidth,
    "circle-stroke-color": strokeColor,
  } = style;
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12"
        cy="12"
        r={radius}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

// Render SVG for Polygon (Fill)
export const renderPolygonSVG = (style: PolygonStyle) => {
  const { "fill-color": fillColor } = style;
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <polygon points="5,1 19,1 22,12 19,23 5,23 2,12" fill={fillColor} />
    </svg>
  );
};
