import React from "react";
import ReactDOM from "react-dom/client"; // Import from "react-dom/client"
import "./index.css";
import App from "./App";
import { UserProvider } from "./UserContext"; // Make sure this path is correct
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "./ConfigContext"; // Import the provider
import theme from "./themes/theme"; // Import the custom theme
import { ThemeProvider } from "@mui/material";
import { LayerProvider } from "./LayerContext";
import { CurrentPopupProvider } from "./PopupContext";
import WebFont from "webfontloader";
import { SnackbarProvider } from "notistack";

WebFont.load({
  google: {
    families: ["Mukta:200,300,400,500,600,700,800", "sans-serif"],
  },
});

// Use createRoot to create a root instance and call render on it
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
if (!clientId) throw new Error("Failed to find the Google OAuth client ID");

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <ConfigProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <LayerProvider>
              <SnackbarProvider>
                <CurrentPopupProvider>
                  <App />
                </CurrentPopupProvider>
              </SnackbarProvider>
            </LayerProvider>
          </ThemeProvider>
        </UserProvider>
      </ConfigProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
