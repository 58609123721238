import React from "react";
import { Box } from "@mui/material";

const Admin: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 66px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="icons/work-in-progress.png"
        alt="Admin Mockup"
        style={{ maxWidth: "30%", maxHeight: "30%" }}
      />
    </Box>
  );
};

export default Admin;
