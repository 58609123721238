import React, { useEffect, useRef } from "react";
import maplibregl, { GeoJSONSource } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useLayers } from "./LayerContext"; // Assuming this is the custom hook from your LayerContext
import { useCurrentPopup } from "./PopupContext";
import "./MapComponent.css";
import { useUser } from "./UserContext";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

// Create a color scale
const colorScale = scaleOrdinal(schemeCategory10);

class PitchToggleControl {
  private _container: HTMLElement;
  private _button: HTMLButtonElement;
  private _map?: maplibregl.Map;
  private _firstClick: boolean = true;

  constructor() {
    this._container = document.createElement("div");
    this._container.className = "maplibregl-ctrl";
    this._button = document.createElement("button");
    this._button.textContent = "3D Mode";
    this._button.style.padding = "7px";
    this._button.style.transition = "color 0.3s ease";
    this._button.onmouseover = () => {
      this._button.style.color = "#fff";
    };
    this._button.onmouseout = () => {
      this._button.style.color = "#000";
    };

    this._container.appendChild(this._button);
  }

  onAdd(map: maplibregl.Map) {
    this._map = map;
    this._button.onclick = () => {
      // if (this._firstClick) {
      //   setTimeout(() => {
      //     window.alert(
      //       "Use the right mouse button to change the view angle, scroll to zoom in and out. Try zooming in close to a site to see the 3D effect!"
      //     );
      //   }, 500);
      //   this._firstClick = false;
      // }
      if (map.getPitch() === 0) {
        map.easeTo({ pitch: 60 });
      } else {
        map.easeTo({ pitch: 0 });
      }
    };
    return this._container;
  }

  onRemove() {
    if (this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._map = undefined;
  }
}

interface MapComponentProps {
  isSidePanelOpen: boolean;
}

/** ! TODO: Popup conditional rendering is a bit confused. Need to only render popups on map if side panel closed, 
but we dont want the entire map to rerender by observing this in a useEffect hook... 
So we are watching a ref to that property instead... Code could be neater 
**/
const MapComponent: React.FC<MapComponentProps> = ({ isSidePanelOpen }) => {
  const { layers, basicAuthLayerUrls, oAuthLayerUrls, setLayerError } =
    useLayers(); // Access layers and the method to toggle visibility from LayerContext
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const popupRef = useRef<maplibregl.Popup | null>(null);
  const isSidePanelOpenRef = useRef(isSidePanelOpen); // Is this bad practice? Using refs to keep track of state? This works because doesnt trigger rerender of map...
  const { setPopupContent } = useCurrentPopup();
  const { basicAuthHeader, oAuthHeader } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  useEffect(() => {
    isSidePanelOpenRef.current = isSidePanelOpen;
  }, [isSidePanelOpen]);

  // Only initialise the map once, when component mounts
  useEffect(() => {
    // Exit if we dont currently have a map
    if (!mapContainerRef.current) return;

    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://api.maptiler.com/maps/satellite/style.json?key=${process.env.REACT_APP_MAPTILER_KEY}`,
      center: [124.2, -16.11], // Example coordinates, update as needed
      zoom: 7,
      pitch: 0,
      maxPitch: 65,
      attributionControl: false,
      // Add basic auth to all requests to our geoserver
      // TODO: Make sure we are not overwriting any existing headers
    });

    map.on("load", () => {
      map.addControl(new maplibregl.NavigationControl());
      const pitchToggleControl = new PitchToggleControl();
      map.addControl(pitchToggleControl, "top-right");

      // Add all the layers to the map, regardless of visibility property
      layers.forEach((layer) => {
        if (layer.type === "raster-dem") {
          map.addSource(layer.id, {
            type: layer.type,
            url: layer.url,
            tileSize: layer.options.tileSize,
          });
          map.setTerrain({
            source: layer.id,
            exaggeration: layer.options.exaggeration,
          });
        } else if (layer.type === "raster") {
          // Add WMS layers etc. (WMS layers are called type: raster in MapLibre GL JS)
          map.addSource(layer.id, {
            type: "raster",
            tiles: [layer.url],
            tileSize: layer?.options?.tileSize || 256,
          });

          map.addLayer({
            id: layer.id,
            type: "raster",
            source: layer.id,
            paint: {
              "raster-opacity": layer.options.opacity || 1, // Use the opacity value from the layer's options object, or default to 1 if it's not set
            },
            layout: {
              visibility: layer.visible ? "visible" : "none",
            },
          });
        } else if (layer.type === "geojson") {
          // ! Add OAuth and BasicAuth headers to geojson requests. Only applied to Geojson layers as this extra step was breaking WMS raster type requests. Possibly due to the string interpolation in the urls?
          // TODO: Eventually add this headers to an external layers service, where we pro-process all input layers, adding any headers or uel params they need
          const getAuthHeaders = () => {
            if (
              layer.url.startsWith(
                "https://maps.rightplacegeo.com/geoserver/culturemap"
              )
            ) {
              if (
                basicAuthLayerUrls.some((layerUrl) =>
                  layer.url.startsWith(layerUrl)
                )
              ) {
                // Add basic auth header if layer requires basic auth
                return basicAuthHeader;
              } else if (
                oAuthLayerUrls.some((layerUrl) =>
                  layer.url.startsWith(layerUrl)
                )
              ) {
                // Add OAuth header if layer requires OAuth
                return oAuthHeader;
              }
            }
            return {};
          };
          // Fetch the GeoJSON data
          fetch(layer.url, { headers: getAuthHeaders() || {} })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              if (layer.geometry === "symbol") {
                if (layer.defaultIcon) {
                  if (layer.icons && Array.isArray(layer.icons)) {
                    // Load each icon in the array
                    layer.icons.forEach((icon, index) => {
                      const loadImageAsync = async () => {
                        const response = await map.loadImage(icon);
                        // Add the loaded image to the style's sprite with the ID 'kitten'.
                        map.addImage(`${layer.id}-${index}`, response.data);
                      };

                      loadImageAsync();
                    });
                  } else {
                    // If layer.icons is not defined, load only the default icon
                    const loadImageAsync = async () => {
                      if (!map.hasImage("defaultIcon")) {
                        const response = await map.loadImage(
                          layer.defaultIcon as string
                        );
                        // Add the loaded image to the style's sprite with the ID 'defaultIcon'.
                        map.addImage("defaultIcon", response.data);
                      }
                    };

                    loadImageAsync();
                  }

                  const layout: any = {
                    visibility: layer.visible ? "visible" : "none",
                    "icon-image":
                      layer.icons && Array.isArray(layer.icons)
                        ? [
                            "case",
                            ["has", "type"],
                            [
                              "match",
                              ["get", "type"],
                              "spring",
                              `${layer.id}-0`,
                              "art",
                              `${layer.id}-1`,
                              "burial",
                              `${layer.id}-2`,
                              "artefacts",
                              `${layer.id}-3`,
                              "defaultIcon", // fallback icon if none of the conditions are met
                            ],
                            ["has", "site_type"],
                            [
                              "match",
                              ["get", "site_type"],
                              "rock art",
                              `${layer.id}-1`,
                              "burial",
                              `${layer.id}-2`,
                              "stone arrangement",
                              `${layer.id}-3`,
                              "defaultIcon",
                            ],
                            "defaultIcon", // Use the default icon for all points if neither "type" nor "site_type" is defined
                          ]
                        : "defaultIcon", // Use the default icon for all points if layer.icons is not defined
                    "icon-size": layer.style.iconSize, // adjust this value as needed
                  };

                  const paint: any = {};

                  if (layer.style.labelField) {
                    layout["text-field"] = layer.style.labelField;
                    layout["text-size"] = layer.style.labelSize || 12;
                    layout["text-font"] = layer.style.labelFont || [
                      theme.typography.fontFamily,
                    ];
                    layout["text-offset"] = layer.style.labelOffset || [1, 0];
                    paint["text-color"] = layer.style.labelColor || "#ffffff";
                  }

                  map.addLayer({
                    id: layer.id,
                    type: "symbol", // Change "point", "line", or "fill" to "symbol"
                    source: {
                      type: "geojson",
                      data: data,
                    },
                    minzoom: layer.options?.minZoom || 0,
                    maxzoom: layer.options?.maxZoom || 22,
                    layout: layout,
                    paint: paint,
                  });
                }
              } else if (layer.styleByField) {
                const paint: any = {};
                const layout: any = {
                  visibility: layer.visible ? "visible" : "none",
                };

                if (layer.style.labelField) {
                  layout["text-field"] = layer.style.labelField;
                  layout["text-size"] = layer.style.labelSize || 12;
                  layout["text-font"] = layer.style.labelFont || [
                    theme.typography.fontFamily,
                  ];
                  layout["text-offset"] = layer.style.labelOffset || [1, 0];
                  paint["text-color"] = layer.style.labelColor || "#ffffff";
                }

                // Collect unique names from the GeoJSON data
                const uniqueNames: Set<string> = new Set();
                data.features.forEach((feature: any) => {
                  uniqueNames.add(feature.properties[layer.styleByField!]);
                });
                const uniqueNamesArray = Array.from(uniqueNames); // Convert set to array if needed

                map.addLayer({
                  id: layer.id,
                  type: layer.geometry,
                  source: {
                    type: "geojson",
                    data: data,
                  },
                  minzoom: layer.options?.minZoom || 0,
                  maxzoom: layer.options?.maxZoom || 22,
                  paint: {
                    // Dynamically map the color based on the unique values of the specified property
                    "fill-color": [
                      "case",
                      // For each unique value of the specified property, assign a color from the color scale
                      // @ts-ignore
                      ...uniqueNamesArray.flatMap((name) => [
                        ["==", ["get", layer.styleByField!], name], // Condition
                        colorScale(name), // Color value corresponding to the condition
                      ]),
                      // Default color if none of the conditions are met
                      // @ts-ignore
                      "#000000", // Default color
                    ],
                    "fill-opacity": 0.35,
                  },
                });
              } else {
                const paint: any = {};
                const layout: any = {
                  visibility: layer.visible ? "visible" : "none",
                };

                if (layer.style.labelField) {
                  layout["text-field"] = layer.style.labelField;
                  layout["text-size"] = layer.style.labelSize || 12;
                  layout["text-font"] = layer.style.labelFont || [
                    theme.typography.fontFamily,
                  ];
                  layout["text-offset"] = layer.style.labelOffset || [1, 0];
                  paint["text-color"] = layer.style.labelColor || "#ffffff";
                }
                map.addLayer({
                  id: layer.id,
                  type: layer.geometry, // "point", "line", or "fill", or "symbol"
                  source: {
                    type: "geojson",
                    data: data,
                  },
                  minzoom: layer.options?.minZoom || 0,
                  maxzoom: layer.options?.maxZoom || 22,
                  paint: layer.style,
                  layout: layout,
                });
              }
            })
            .catch((error) => {
              // If an error occurs, set the error property on the layer
              enqueueSnackbar(
                `You dont have permission to access ${layer.id} layer, removing it from the map`,
                { variant: "error" }
              );
              setLayerError(layer.id, true);
            });

          // Additional layer types can be handled here
          // End of For Each Layer
        }
      });
    });

    // Set the map instance to mapRef.current
    mapRef.current = map;

    return () => {
      if (map) map.remove();
    };
  }, []);

  // Separate UseEffect block to deal with layer visibility changes. Moved out of map initialisation block to avoid rerendering map on every visibility change
  useEffect(() => {
    // Assuming mapRef.current is already initialized
    if (!mapRef.current || !layers) return;

    const updateLayerVisibility = (layerId: string, visibility: boolean) => {
      const map = mapRef.current;
      if (map && map.getLayer(layerId)) {
        map.setLayoutProperty(
          layerId,
          "visibility",
          visibility ? "visible" : "none"
        );
      }
    };

    layers.forEach((layer) => {
      updateLayerVisibility(layer.id, layer.visible);
    });

    // This effect should run only when `layers` changes in a way that affects visibility.
    // It assumes layers is a stable reference or that changes indicate actual visibility changes.
  }, [layers]);

  useEffect(() => {
    if (!mapRef.current) return;

    const handleClick = (e: maplibregl.MapMouseEvent) => {
      const map = mapRef.current!;

      const features = map.queryRenderedFeatures(e.point);
      if (!features || features.length === 0) return;

      const feature = features[0];
      const layerId = feature.layer.id; // Get the ID of the clicked layer

      // Find the layer configuration for the clicked layer
      const layerConfig = layers.find((layer) => layer.id === layerId);
      if (!layerConfig || layerConfig.disablePopups) return; // If the layer doesn't exist or popups are disabled, return early

      const content = setPopupContent(feature.properties);

      if (!isSidePanelOpen) {
        const popup = new maplibregl.Popup({
          maxWidth: "500px",
          anchor: "left",
        })
          .setLngLat(e.lngLat)
          .setHTML(content ?? "")
          .addTo(map);

        popupRef.current = popup;
      }
    };

    mapRef.current.on("click", handleClick);

    return () => {
      if (mapRef.current) {
        mapRef.current.off("click", handleClick);
      }
    };
  }, [setPopupContent, isSidePanelOpen]);

  useEffect(() => {
    if (popupRef.current && isSidePanelOpen) {
      popupRef.current.remove();
      popupRef.current = null;
    }
  }, [isSidePanelOpen]);

  return <div ref={mapContainerRef} className="mapContainer" />;
};

export default MapComponent;
