import React from "react";
import { Typography, Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useConfig } from "./ConfigContext";

const LoginMessage: React.FC = () => {
  const config = useConfig();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
        padding: 50,
      }}
    >
      {/* <LockIcon sx={{ fontSize: 100, color: 'text.secondary' }} /> */}
      <img src={config.loginImagePath ?? "icons/lock.png"} alt="Login Image" style={{ width: "200px" }} />
      <Typography id="login-modal-description" variant="h4" align="center">
        {config.loginMessage}
      </Typography>
    </Box>
  );
};

export default LoginMessage;
