import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import LoginModal from "./LoginModal";
import TitleBar from "./TitleBar";
import MapComponent from "./MapComponent";
import SidePanel from "./SidePanel";
import { useConfig } from "./ConfigContext";
import { Fab } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EditorComponent from "./EditorComponent";
import AdminComponent from "./AdminComponent";

const AppContent: React.FC = () => {
  const { profile, oAuthHeader } = useUser();
  const config = useConfig();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    document.title = config.appTitle;
  }, [config]);

  const handleCloseModal = () => {};

  // ! TODO: Remove this, its just for demo purposes
  const setCurrentTabAndCloseSidePanel = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    if (tabIndex !== 0) {
      setIsSidePanelOpen(false);
    }
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  return (
    <>
      <TitleBar
        currentTab={currentTab}
        setCurrentTab={setCurrentTabAndCloseSidePanel}
      />
      {/* Only render the app if we have OAuth credentials ready */}
      {profile && oAuthHeader ? (
        <>
          {currentTab === 0 && (
            <MapComponent isSidePanelOpen={isSidePanelOpen} />
          )}
          {currentTab === 1 && <EditorComponent />}
          {currentTab === 2 && <AdminComponent />}
          <SidePanel open={isSidePanelOpen} onClose={toggleSidePanel} />
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "absolute", bottom: 16, left: 16 }}
            onClick={toggleSidePanel}
          >
            <MenuIcon fontSize="large" />
          </Fab>
        </>
      ) : (
        <LoginModal onClose={handleCloseModal} />
      )}
    </>
  );
};

export default AppContent;
