import React, { createContext, useContext, ReactNode } from "react";
import rawConfig from "./config.json";
import { Config, MapLayer } from "./types";

// Perform a runtime check or assertion here to ensure rawConfig matches the Config type
const config = rawConfig as Config;

const ConfigContext = createContext<Config>(config);

export const useConfig = (): Config => {
  const context = useContext(ConfigContext);
  return context; // Since you're now providing a default value, the undefined check is not necessary
};

interface ConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};
