// LayerContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { MapLayer } from "./types"; // Adjust the import path as necessary
import { processLayerUrls } from "./utilities/processLayerUrls";
import { useConfig } from "./ConfigContext";
import { useUser } from "./UserContext";

interface LayerContextType {
  layers: MapLayer[];
  setLayers: React.Dispatch<React.SetStateAction<MapLayer[]>>;
  toggleLayerVisibility: (id: string) => void;
  basicAuthLayerUrls: string[];
  setLayerError: (id: string, error: boolean) => void;
  oAuthLayerUrls: string[];
}

const LayerContext = createContext<LayerContextType | undefined>(undefined);

export const LayerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { profile } = useUser(); // Use the authentication state
  const config = useConfig();
  const [layers, setLayers] = useState<MapLayer[]>([]);

  // Effect to reload layers based on the current user
  useEffect(() => {
    const initialLayers = processLayerUrls(config.mapLayers).map((layer) => ({
      ...layer,
      visible: layer.visible !== undefined ? layer.visible : true,
    }));

    setLayers(initialLayers);
  }, [profile, config.mapLayers]); // Depend on the profile and configuration


  const basicAuthLayerUrls = layers
    .filter((layer) => layer.useBasicAuth)
    .map((layer) => layer.url);

  const oAuthLayerUrls = layers
    .filter((layer) => layer.useOAuth)
    .map((layer) => layer.url);

  const toggleLayerVisibility = (id: string) => {
    setLayers((currentLayers) =>
      currentLayers.map((layer) => {
        if (layer.id === id) {
          return { ...layer, visible: !layer.visible };
        }
        return layer;
      })
    );
  };

  const setLayerError = (id: string, error: boolean) => {
    setLayers((currentLayers) =>
      currentLayers.map((layer) => {
        if (layer.id === id) {
          return { ...layer, error };
        }
        return layer;
      })
    );
  };

  return (
    <LayerContext.Provider
      value={{
        layers,
        setLayers,
        toggleLayerVisibility,
        setLayerError,
        basicAuthLayerUrls,
        oAuthLayerUrls,
      }}
    >
      {children}
    </LayerContext.Provider>
  );
};

// Custom hook to use the context
export const useLayers = () => {
  const context = useContext(LayerContext);
  if (context === undefined) {
    throw new Error("useLayers must be used within a LayerProvider");
  }
  return context;
};
