import { Theme } from "@mui/material";
import { rgba } from "polished";

interface PopupProperties {
  [key: string]: any;
}

export const generatePopupContent = (
  properties: PopupProperties,
  theme: Theme
) => {
  const titleKey = "name"; // Replace with your desired title key
  const imageKey = "image_url"; // Replace with your desired image key
  const audioKey = "pronunciation"; // Key for the audio property
  const documentKey = "documents"; // Key for the documents property
  const descriptionKey = "description"; // Key for the description property
  let title = "";
  let pronunciation = "";
  let description = "";
  const backgroundColor = rgba(theme.palette.secondary.main, 0.05);
  let content = `<div style='border-radius: 5px; padding: 10px; background-color: ${backgroundColor}; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); font-size: 14px; '>`;
  let tableContent = `<table style='width: 100%; border-collapse: collapse; margin-top: 20px;'>`;
  const styles = `
    <style>
      audio {
        width: 110px;
        height: 30px;
        animation: audioWidth 0.1s forwards;
      }

      audio::-webkit-media-controls-panel {
        justify-content: center;
      }

      audio::-webkit-media-controls-volume-slider {
        display: none !important;
        min-width: 0;
      }

      audio::-webkit-media-controls-timeline-container {
        display: none !important;
        min-width: 0;
      }

      audio::-webkit-media-controls-time-remaining-display {
        display: none !important;
        min-width: 0;
      }

      audio::-webkit-media-controls-timeline {
        display: none !important;
        min-width: 0;
      }

      @keyframes audioWidth {
        from {
          width: 110px;
        }
        to {
          width: 84px;
        }
      }

      table {
        border: 1px solid ${rgba(theme.palette.secondary.main, 0.2)};
      }
  
      th, td {
        border: 1px solid ${rgba(theme.palette.secondary.main, 0.2)};
        padding: 5px;
        text-align: left;
      }
    </style>
  `;

  Object.entries(properties).forEach(([key, value]) => {
    if (key === titleKey) {
      title = `<h2 style='font-weight: bold; color: ${theme.palette.primary.main}; display: inline; vertical-align: top;'>${value}</h2>`;
    } else if (key === audioKey) {
      pronunciation = `<audio controls style='display: inline; '><source src="${value}" type="audio/mpeg">Your browser does not support the audio element.</audio>`;
    } else if (key === imageKey) {
      content += `<img src="${value}" alt="${key}" style='max-width: 100%; height: auto;'/>`;
    } else if (key === descriptionKey) {
      description = `<h3 style='font-weight: bold; color: ${theme.palette.primary.main};'>${key}</h3><p>${value}</p>`;
    } else if (key === documentKey) {
      tableContent += `<tr><td style='font-weight: bold; color: ${theme.palette.primary.main};'>${key}</td><td><a href="${value}" target="_blank" rel="noopener noreferrer">Link to Report</a></td></tr>`;
    } else {
      tableContent += `<tr><td style='font-weight: bold; color: ${theme.palette.primary.main};'>${key}</td><td>${value}</td></tr>`;
    }
  });

  tableContent += `</table>`;

  const editButton = `
  <button 
    style='
      position: relative;
      background-color: ${rgba(theme.palette.secondary.main, 0.4)}; 
      color: white; 
      border: none; 
      padding: 5px 10px; 
      cursor: not-allowed;
      font-family: ${theme.typography.fontFamily};
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    ' 
    onmouseover='this.childNodes[1].style.display = "block"'
    onmouseout='this.childNodes[1].style.display = "none"'
  >
    EDIT
    <div style='
      display: none;
      position: absolute;
      width: 90px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    '>
      NOT ALLOWED
    </div>
  </button>
`;

  const headerContent = `
  <table style='width: 100%; border: none;'>
    <tr>
      <td style='vertical-align: top; border: none; width: auto;'>${title}</td>
      <td style='vertical-align: top; border: none; width: auto;'>${pronunciation}</td>
      <td style='vertical-align: top; text-align: right; border: none; display: flex; align-items: center; justify-content: flex-end;'>${editButton}</td>
    </tr>
  </table>
`;

  const fullContent =
    `<div style='font-family: ${theme.typography.fontFamily};'>` +
    styles +
    headerContent +
    content +
    description +
    tableContent +
    "</div>";

  return fullContent;
};
