import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useConfig } from "./ConfigContext";
import UserButton from "./UserButton";

const Logo = styled("img")({
  marginRight: 10,
  height: "50px",
});

interface TitleBarProps {
  currentTab: number;
  setCurrentTab: (value: number) => void;
}

const TitleBar: React.FC<TitleBarProps> = ({ currentTab, setCurrentTab }) => {
  const config = useConfig();
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, marginLeft: 5 }}
        >
          <Logo src={config.logoPath} alt="Icon" />
        </IconButton>
        <Typography
          variant="h1"
          component="div"
          sx={{ flexGrow: 1, fontWeight: "bold" }}
        >
          {config.appTitle}
        </Typography>
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Map" />
          <Tab label="Editor" />
          <Tab label="Admin" />
        </Tabs>
        <UserButton />
      </Toolbar>
    </AppBar>
  );
};

export default TitleBar;
