// SidePanel.tsx
import React from "react";
import { CardContent, Stack } from "@mui/material";
import LayerList from "./LayerList";
import FeatureInfoPanel from "./FeatureInfoPanel";
import { useCurrentPopup } from "./PopupContext";
import "./SidePanel.css"; // Import the CSS file

interface SidePanelProps {
  onClose: () => void;
  open: boolean;
}

const SidePanel: React.FC<SidePanelProps> = ({ onClose, open }) => {
  const { popupContent, clearPopup } = useCurrentPopup();
  if (!open) {
    return null;
  }

  return (
    <div className="sidePanel">
      <CardContent>
        <Stack spacing={30}>
          <LayerList />
          <FeatureInfoPanel content={popupContent} onClose={clearPopup} />
        </Stack>
      </CardContent>
    </div>
  );
};

export default SidePanel;
