import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Collapse,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLayers } from "./LayerContext";
import { GeoJsonLayer, LegendLayer, MapLayer, RasterLayer } from "./types";
import {
  renderCircleSVG,
  renderLineSVG,
  renderPolygonSVG,
} from "./utilities/renderSVG";
import { styled } from "@mui/material/styles";

const LayerItem = ({ layer }: { layer: LegendLayer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { toggleLayerVisibility } = useLayers();

  const getStyleIcon = (layer: LegendLayer, width: number, height: number) => {
    // TODO: Implement style icons for wms type layers etc
    if (layer.type !== "geojson") {
      return null;
    }

    const viewBox = `0 0 ${width} ${height}`;

    switch (layer.geometry) {
      case "line":
        return (
          <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* @ts-ignore */}
            {renderLineSVG(layer.style)}
          </svg>
        );
      case "circle":
        return (
          <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* @ts-ignore */}
            {renderCircleSVG(layer.style)}
          </svg>
        );
      case "fill":
        return (
          <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* @ts-ignore */}
            {renderPolygonSVG(layer.style)}
          </svg>
        );
      case "symbol":
        const iconUrl = layer.defaultIcon; // Replace with the actual property that holds the icon URL
        return (
          <img
            src={iconUrl}
            alt="icon"
            style={{ width: `${width}px`, height: `${height}px` }}
          />
        ); // Render the image directly
      default:
        return null; // Handle default case or other geometries
    }
  };

  return (
    <>
      <Grid container spacing={15} alignItems="center">
        <Grid item>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
        <Grid item>{getStyleIcon(layer, 24, 24)}</Grid>
        <Grid item xs style={{ overflow: "hidden" }}>
          <Typography
            variant="body2"
            noWrap={false}
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              wordBreak: "break-all",
            }}
          >
            {layer.id}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => toggleLayerVisibility(layer.id)}>
            {layer.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Table size="small" sx={{ pl: 1, tableLayout: "fixed", width: "100%" }}>
          <TableBody>
            {Object.entries(layer).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ wordWrap: "break-word", maxWidth: "130px" }}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </TableCell>
                <TableCell style={{ wordWrap: "break-word" }}>
                  {typeof value === "object"
                    ? JSON.stringify(value)
                    : typeof value === "string" || typeof value === "number"
                    ? value
                    : "N/A"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
};

const LayerList = () => {
  const { layers } = useLayers();
  const [isOpen, setIsOpen] = useState(true);
  // Filter layers to only include those of type LegendItem
  const legendLayers = layers
    .filter((layer): layer is GeoJsonLayer | RasterLayer => {
      return (
        (layer.type === "geojson" || layer.type === "raster") && !layer.error // Exclude layers that had an error loading
      ); 
    })
    .reverse(); // reverse the list so that the order is same as drawing order on map

  // Hacky way to reduce the extra padding at the bottom of the MapLayers panel when its collapsed
  const CardContentNoPadding = styled(CardContent)(`
    &:last-child {
      padding-bottom: 6px;
    }
  `);

  return (
    <Card elevation={3} sx={{ m: 1 }}>
      <CardContentNoPadding>
        <Typography variant="h4" gutterBottom>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}{" "}
          </IconButton>{" "}
          Map Layers
        </Typography>
        <Collapse in={isOpen}>
          <Stack spacing={2}>
            {legendLayers.map((layer) => (
              <LayerItem key={layer.id} layer={layer} />
            ))}
          </Stack>
        </Collapse>
      </CardContentNoPadding>
    </Card>
  );
};

export default LayerList;
