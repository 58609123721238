import { createTheme } from "@mui/material/styles";
import config from "../config.json"; // Adjust the path as necessary

// Extract colorScheme from config
const { colorScheme } = config;

// Create a custom MUI theme using colors from config
const theme = createTheme({
  palette: {
    primary: {
      main: colorScheme.primary,
    },
    secondary: {
      main: colorScheme.secondary,
    },
  },
  typography: {
    fontSize: 10, // Set the global font size
    fontFamily: "Mukta, sans-serif",
    h1: {
      fontSize: "1.5rem",
    },
    h2: {
      fontSize: "1.4rem",
    },
    h3: {
      fontSize: "1.3rem",
    },
    h4: {
      fontSize: "1.2rem",
    },
    h5: {
      fontSize: "1.1rem",
    },
    h6: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontSize: "0.75rem",
    },
    body1: {
      fontSize: "0.875rem",
    },
    body2: {
      fontSize: "0.75rem",
    },
    button: {
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "0.625rem",
    },
  },
  spacing: 0.5, // Reduce the global spacing
  // ...
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem", // Set the default font size for TableCell
        },
      },
    },
  },
});

export default theme;
