import React from "react";
import { Modal, Typography, Paper, Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"; // If using MUI v5, ensure you've installed @mui/icons-material
import { useUser } from "./UserContext";
import LoginMessage from "./LoginMessage";

// Updated modal style for better alignment with MUI design principles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400, // Set a fixed width for the modal for better consistency
  bgcolor: "background.paper",
  boxShadow: 24, // Use MUI's shadow for depth
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Center content within the modal
  padding: "30px",
};

interface LoginModalProps {
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ onClose }) => {
  const { googleLogin } = useUser();

  // Assuming useGoogleLogin hook is correctly initialized elsewhere or in useUser
  const handleLogin = () => {
    // Placeholder for initiating the login process

    googleLogin();
    onClose(); // Consider closing the modal post login or upon successful login callback
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Paper sx={modalStyle}>
        <Typography
          id="login-modal-title"
          variant="h3"
          component="h2"
          gutterBottom
        >
          Sign in Required
        </Typography>

        <LoginMessage />
        {/* <Typography id="login-modal-description" sx={{ mb: 2 }}>
          Please sign in with your Google account to continue.
        </Typography> */}
        <Button
          variant="contained"
          startIcon={<GoogleIcon />}
          onClick={handleLogin}
          sx={{ textTransform: "none" }}
        >
          Login with Google
        </Button>
      </Paper>
    </Modal>
  );
};

export default LoginModal;
