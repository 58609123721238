import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, IconButton, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from "dompurify";

interface FeatureInfoPanelProps {
  content: string | null;
  onClose: () => void;
}

const FeatureInfoPanel: React.FC<FeatureInfoPanelProps> = ({
  content,
  onClose,
}) => {
  const [sanitizedContent, setSanitizedContent] = useState<string | null>(null);

  useEffect(() => {
    if (content) {
      // Temporarily clear the content for a smooth transition
      setSanitizedContent(null);
      // Then set the sanitized new content after a short delay
      setTimeout(() => {
        const newSanitizedContent = DOMPurify.sanitize(content);
        setSanitizedContent(newSanitizedContent);
      }, 100); // Adjust this delay as needed
    } else {
      // If there's no content, set sanitizedContent to null immediately
      setSanitizedContent(null);
    }
  }, [content]); // Depend on content to re-trigger effect

  if (!sanitizedContent) {
    // Optionally, handle the loading state or simply return null to wait for content
    // return <LoadingIndicator />; or
    return null; // For simplicity, we're returning null here
  }

  return (
    <Fade in={true} timeout={1000}>
      <Card variant="elevation">
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Use sanitizedContent with dangerouslySetInnerHTML */}
          <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </CardContent>
      </Card>
    </Fade>
  );
};

export default FeatureInfoPanel;
