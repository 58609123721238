import React, { useState } from "react";
import { useUser } from "./UserContext"; // Adjust import path as needed
import { Avatar, IconButton, Tooltip, Menu, MenuItem, ListItemIcon, Typography, Divider } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login"; // For login icon
import Logout from "@mui/icons-material/Logout"; // For logout icon

const UserButton = () => {
  const { profile, logout } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose(); // Close the menu upon logout
    // Optionally, redirect the user or display a message
  };

  return (
    <div>
      {profile ? (
        <>
          <Tooltip title="User Settings">
            <IconButton onClick={handleClick}>
              <Avatar src={profile.picture} alt={profile.name || "User"} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose} // Close menu when an item is clicked
          >
            <MenuItem disabled>
              <Typography variant="subtitle1">{profile.name}</Typography>
            </MenuItem>
            <MenuItem disabled>
              <Typography variant="body2">{profile.email}</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Tooltip title="Login">
          <IconButton onClick={() => { /* Trigger login logic here */ }}>
            <LoginIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default UserButton;
