import { MapLayer } from "../types"; // Import the MapLayer type

/**
 * Enhances an array of MapLayer objects with full request URLs, dynamically determining if query parameter values
 * refer to environment variables or plain strings. If a value matches a predefined pattern suggesting it's an
 * environment variable (e.g., starting with REACT_APP_ or NODE_), the function attempts to resolve it from
 * process.env. If the variable is not found or if the value does not match the pattern, it is used as is.
 * This allows for flexible configuration of layer URLs based on both static values and environment-specific variables.
 *
 * @param {MapLayer[]} layers - An array of MapLayer objects, each potentially containing query parameters.
 * @returns {MapLayer[]} An array of MapLayer objects with their `url` properties enhanced to include query parameters.
 */
export function processLayerUrls(layers: MapLayer[]): MapLayer[] {
  // Define a pattern for environment variable names
  const envVarPattern = /^(REACT_APP_|NODE_)[A-Z0-9_]+$/;

  return layers.map((layer) => {
    const queryParams = layer.queryParams
      ? Object.entries(layer.queryParams)
          .map(([key, value]) => {
            let finalValue = value;

            // Check if value matches the environment variable pattern
            if (envVarPattern.test(value)) {
              // Attempt to retrieve the environment variable, fallback to the original value if not found
              const envValue = process.env[value as keyof NodeJS.ProcessEnv];
              finalValue = envValue !== undefined ? envValue : value;
            }

            // TODO: If we need to encode the special characters, re-enable this line. NB: It currently removes the {} around bbox values which breaks WMS requests.
            // Ensure the final value is properly encoded to be safely included in a URL
            // finalValue = encodeURIComponent(finalValue);


            return `${key}=${finalValue}`;
          })
          .join("&")
      : "";

    // Construct the full URL including the query string if applicable
    const url = queryParams ? `${layer.url}?${queryParams}` : layer.url;

    return {
      ...layer,
      url,
    };
  });
}
